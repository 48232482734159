<script>
import Vue from "vue";
import store from "@/state/store";

import { sameAs  } from "vuelidate/lib/validators";

import { mapState } from "vuex";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/auth";
import {
  AccountStatus,
  notificationMethods, SessionLoginState
} from "@/state/helpers";

export default {
  page: {
    title: "Account setup",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      terms: false,
      submitted: false,
      validation_error: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    terms: {
      sameAs: sameAs( () => true )
    }
  },
  methods: {
    ...notificationMethods,
    handle_fail_and_reset() {
      this.submitted = false;
      this.$swal( {
        icon: 'error',
        title: this.$t('error.server.generic.title'),
        text: this.$t('error.server.generic.message')
      });
    },
    handle_success(state) {
      const router = this.$router; // Promise hell...
      if(state == SessionLoginState.LOGGED_IN) {
        location.replace('https://cftools.cloud');
      } else if(state == SessionLoginState.IN_SETUP) {
        router.push({name: "setup_account"}).catch((error) => {});
      }
    },
    try_submit() {
      if(this.submitted) return;
      this.submitted = true;

      this.$v.$touch();
      if(this.$v.$invalid) {
        this.validation_error = true;
        this.submitted = false;
      } else {
        this.validation_error = false;

        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          }
        })
        .then(data => {
          /* Actual request */
          let payload = {
            acsrf_token: data.acsrf_token
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/accept-tos', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
              .then(response => {
                return response.json();
              })
              .then(data => {
                if(!data.status) {
                  if(data.error == 'invalid-state') {
                    /* Local state got desynchronized, reevaluating */
                    console.log('Resolving state desynchronization');
                    this.$router.push({name: "login"}).catch((error) => {});
                  } else this.handle_fail_and_reset();
                }
                else this.handle_success(data.followup);
              })
              .catch(error => {
                console.log(`[ERROR] ${error}`);
                this.handle_fail_and_reset();
              });
          /* --- */
        })
        .catch(error => {
          console.log(error);
        });
      }

    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div class="bg-soft-primary">
            <div class="row">
              <div class="col-12">
                <div class="text-primary p-3">
                  <h4 class="text-white text-right p-0">{{$t('setup.title')}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link>
            </div>
            <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
            >{{notification.message}}</div>
            <b-alert
                v-model="validation_error"
                class="mt-3 text-center"
                variant="danger"
            >{{$t('setup.legal.terms.error')}}</b-alert>

            <b-form class="p-2" @submit.prevent="try_submit">
              <h5 class="text-center">{{$t('setup.legal.terms.prompt')}}</h5>
              <div class="row justify-content-center pb-5">
                <div class="col-lg-4">
                  <a href="https://cftools.cloud/legal/terms-of-use" target="_blank">
                    {{$t('setup.legal.terms.title')}} <i class="fas fa-external-link-square-alt"></i>
                  </a>
                </div>
              </div>

              <div class="custom-control custom-checkbox text-center">
                <input
                    id="customControlInline"
                    type="checkbox"
                    class="custom-control-input"
                    v-model="terms"
                    @change="$v.terms.$touch()"
                    :class="{invalid: $v.terms.$invalid}"
                />
                <label class="custom-control-label" for="customControlInline">{{$t('setup.legal.terms.statement')}}</label>
              </div>
              <div class="mt-3">
                <b-button type="submit" variant="primary" class="btn-block">{{$t('generic.continue')}}</b-button>
              </div>

            </b-form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="mt-3 text-center">
      <small>
        © 2017 - {{new Date().getFullYear()}} {{$t('generic.legal_disclaimer')}} {{$t('generic.provider')}}<br>
        <small style="font-size: 10px;">
          <a href="https://cftools.cloud/legal/imprint" style="color: unset !important;">Imprint</a>
          /
          <a href="https://cftools.cloud/legal/privacy" style="color: unset !important;">Privacy policy</a>
        </small>
      </small>
    </div>
  </Layout>
</template>

<style lang="scss" module></style>
